import { Button, Menu, MenuItem } from '@emburse/embark-core';
import React from 'react';
import PeopleIcon from '@mui/icons-material/People';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { v4 } from 'uuid';

export interface UserRoleFilterDropdownProps {
    onSelect: (value: string) => void
}
export enum UserRole {
    Viewer,
    Creator,
    Admin
}
const UserRoleFilterDropDown = (props: UserRoleFilterDropdownProps) => {

    const [anchor, setAnchor] = React.useState(null);
    const [menuOpen, setMenuOpen] = React.useState(false);
    const [value, setValue] = React.useState("All Users");

    const openMenu = event => {
        setAnchor(event.currentTarget);
        setMenuOpen(true);
    };

    const closeMenu = () => {
        setMenuOpen(false);
    };

    const menuItemClick = (value: string) => {
        setValue(value);
        setMenuOpen(false);
        props.onSelect(value);   
    };
    
    return (
        <span>
            <Button startIcon={<PeopleIcon />} endIcon={menuOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />} color="secondary" onClick={openMenu} >{value}</Button>
            <Menu open={menuOpen} anchorEl={anchor} onClose={closeMenu}>
                <MenuItem onClick={() => menuItemClick("All Users")}>All Users</MenuItem>
                {
                    Object.values(UserRole).map((name, i) => {
                        if(UserRole[i] !== undefined) // .values spits out the integers of the Enum as well and we don't want that.
                            return <MenuItem key={v4()} onClick={() => menuItemClick(name as string)}>{name}</MenuItem>;
                    })
                }
            </Menu>
        </span>
    );
};

export default React.memo(UserRoleFilterDropDown);