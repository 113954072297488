import './UTPModal.scss';
import { useDispatch } from 'react-redux';
import React from 'react';
import EmptyFolder from '../Icons/EmptyFolder';
import { hideModal } from '../../store/actions';
import {
    Button,
    ButtonContainer,
    Modal,
    ModalFooter,
    ModalHeader,
    Link } from '@emburse/embark-core';

export default ({modal}) => {
    const dispatch = useDispatch()

    const closeModal = () => {
        dispatch(hideModal())
    };

    const modalHeader = (<ModalHeader title="Upgrade Emburse Analytics" showClose={true} onCloseClicked={closeModal}/>);

    const modalFooter = (
        <ModalFooter>
            <ButtonContainer alignButtons={'right'}>
                <Button variant="contained" disabled={modal.loading} onClick={closeModal}>
                    Close 
                </Button>
            </ButtonContainer>
        </ModalFooter>
    );
    return (
        <Modal open modalHeader={modalHeader} modalFooter={modalFooter} onClose={closeModal} isLoading={modal.loading}>
            <div className="da-modal-form">
                <EmptyFolder />
                <div>
                    <p>Upgrade <Link href="https://www.emburse.com/solutions/analytics" target="_blank">Emburse Analytics</Link> to:</p> 
                    <ul>
                        <li>build your own dashboards</li>
                        <li>reference custom fields in your dashboards and reports</li>
                        <li>additional sharing capabilities</li>
                        <li>schedule report distribution</li>
                        <li>grant access to all your people managers</li>
                    </ul>    
                    <p>Contact your Customer Success Manager to learn more!</p>
                </div>
            </div>
        </Modal>
    )
}