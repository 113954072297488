import React from 'react';
import { RootStateOrAny, useSelector, useDispatch } from 'react-redux';
import { UPGRADE_TO_PRO_MODAL } from '../../store/constants';
import UTPModal from '../UTPModal/UTPModal';
import { showModal } from '../../store';
import { IHasClickEvent } from '@interfaces';
import { Button } from '@emburse/embark-core';

import { IFFlags, FFlags, getFlag } from "@enums";
export interface UTPButtonProps extends IHasClickEvent {

}

export default () => {
    const dispatch = useDispatch();
    const modal = useSelector((state: RootStateOrAny) => state.modal);
    
    const handleCreateUTPModel = () => {
        dispatch(showModal(UPGRADE_TO_PRO_MODAL));
    };
    
    const flags: IFFlags = useSelector(
        (state: RootStateOrAny) => state.user.userData.flags
      );
    const deprecateUTPButton = (
        <>
            <Button onClick={handleCreateUTPModel} variant="outlined">Upgrade</Button>
            { modal.type === UPGRADE_TO_PRO_MODAL && <UTPModal modal={modal}/>}
        </>
        );

    return flags?.[getFlag(FFlags.EnablePendoUpgradeToProModal)] ? 
        <>
            {/* the modal will be controlled via Pendo targeting the data-qa selector */}
            <Button data-qa="upgradeToProModal" variant="outlined">Upgrade</Button>
            {modal.type === UPGRADE_TO_PRO_MODAL && <UTPModal modal={modal}/>}
        </>  
        : deprecateUTPButton;
};