import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router";

import { useConfigCatClient, User, UserAttributeValue } from "configcat-react";
import {
  fetchFolderContent,
  fetchFolders,
  fetchModels,
  getLookerClient,
  getUserFeatureFlags,
  initLooker,
} from "./store";
import {
  DASHBOARDS_ROUTE,
  EXPLORE_ROUTE,
  FOLDERS_ROUTE,
  FOLDERS_HOME_ROUTE,
  FOLDERS_ALL_ROUTE,
  LOGOUT_ROUTE,
  LOOKS_ROUTE,
  PROVISIONING_ROUTE,
  ROOT_ROUTE,
  UNAUTHORIZED_ROUTE,
  ERROR_ROUTE,
  ADMIN_INTERNAL_TOOLS_ROUTE,
  ADMIN_PAGE_ROUTE,
  ADMIN_USER_MANAGEMENT,
  ADMIN_BURST_SCHEDULE_MANAGEMENT,
  ADMIN_SCHEDULE_MANAGEMENT,
  ADMIN_INTERNAL_CUSTOMERS_ROUTE,
  LICENSE_LIMIT_EXCEEDED_ROUTE,
  PROVISIONED_LICENSE_COUNT_EXCEEDED_ROUTE
} from "./store/routs";
import "./App.scss";
import { useFlags } from "launchdarkly-react-client-sdk";
import { IFFlags, FFlags, getFlag, setShouldFormatFlag } from "./enums/FFlags";
import {SessionStatus} from './enums';
import { ErrorBoundary } from "react-error-boundary";
import {
  ActionBar,
  DashboardFrameV1,
  DashboardFrame,
  ErrorFallback,
  AlertHandler,
  ErrorPage,
  ExploreFrameV1,
  ExploreFrame,
  FolderView,
  Logout,
  LookFrameV1,
  LookFrame,
  Navigation,
  NotFound,
  PrivateRoute,
  Provisioning,
  Unauthorized,
  InternalRoute,
  InternalTools,
  DrawerProvider,
} from "@components/index";
import { useInitRoute } from "./hooks";
import { IState } from "@src/interfaces";
import { useErrorManager } from "./hooks/useErrorManager";
import { AuthContext } from "@components/AuthProvider/AuthProvider";
import { IUseAuthInterface } from "./hooks/useAuth";
import { useContext } from "react";
import {
  AdminPage,
  CustomerSearchPage,
  AnalyticsAPIProvider,
  Contexts,
} from "@src/components";
import LookerProvider from "./components/Contexts/LookerProvider";
import { v4 } from "uuid";
import {
  LicenseLimitExceeded,
  LicenseLimitExceededForProvisionedUser
} from "./components/views/LicenseLimits/LicenseLimitExceeded";

export default () => {
  const dispatch = useDispatch();
  const ccClient = useConfigCatClient();
  const [ccFlags, setCcFlagsToState] = useState({});
  /**
   * @deprecated
   * remove ldFlags when deprecating analytics_app_enableconfigcat
   */
  const ldFlags = useFlags();
  
  /**
   * use bu_unique_id for config cat flag targeting
   * https://configcat.com/docs/advanced/user-object/#identifier-property 
   * */
  const ccCustomAttributes: {[key: string]: UserAttributeValue} = {};
  const userConfig = JSON.parse(localStorage.getItem('user') || '{}');
  if(userConfig.business_unit)
    ccCustomAttributes["bu"] = userConfig.business_unit;
  if(userConfig.bu_env)
    ccCustomAttributes["bu_env"] = userConfig.bu_env;

  const ccUserIdentifier: User = new User(
    userConfig.bu_unique_id as string,
    undefined,
    undefined,
    ccCustomAttributes,
  );
  let initRoute: String = useInitRoute();
  // const { activeFolderId } = useSelector((state: IState) => state.folders);
  const {folderStructure} = useSelector((state: IState) => state.folders);

  const {user}: IUseAuthInterface = useContext(AuthContext);
  useErrorManager();

  useEffect(() => {
    const ccFlagsMap: IFFlags = {};

    const getCcFlags = () => {
      ccClient && ccClient.getAllValuesAsync(ccUserIdentifier)
        .then(settingKeyValues => {
          settingKeyValues.forEach(i => ccFlagsMap[i.settingKey] = i.settingValue);
          setCcFlagsToState(ccFlagsMap);

          // normally, we'd call getFlag() to get value of any flag but since _setShouldFormatFlag hasn't been set yet, hardcode configCat flag lookup, before all the flags are dispatched to the store
          if (ccFlagsMap["analytics_app_enableconfigcat"]) {
            setShouldFormatFlag(true);
            dispatch(getUserFeatureFlags(ccFlagsMap));
          } else {
            // can remove this when analytics_app_enableconfigcat is deprecated
            dispatch(getUserFeatureFlags(ldFlags));
          }      
        });
      };

    getCcFlags();

    if ((localStorage.getItem('auth_status') == "true") && !user?.license_limit_exceeded) {

      if (ccFlagsMap["analytics_app_cra_9875_reuses_looker_embed_session"]) {
        dispatch(getLookerClient());
      } else {
        dispatch(initLooker());
      }
      if (ccFlagsMap["analytics_app_cra_9507_show_archive_folder_in_menu"]) {
        dispatch(fetchFolderContent('all', folderStructure));
      }
      if (user?.authorized || localStorage.getItem('session_status') === SessionStatus.AUTHORIZED) {
        dispatch(fetchFolders());
        dispatch(fetchModels());
      }
    }
  }, [dispatch, user?.authorized]);

  const renderAdminSchedules = () => {
    return <>
      <PrivateRoute
        exact
        path={ADMIN_PAGE_ROUTE}
        component={AdminPage}
      >
        {!user?.tenant_admin && <Redirect to="/" />}
      </PrivateRoute>
      <PrivateRoute
          exact
          path={ADMIN_SCHEDULE_MANAGEMENT}
          component={AdminPage}
      >
        {!user?.tenant_admin && <Redirect to="/" />}
      </PrivateRoute>
      <PrivateRoute
          exact
          path={ADMIN_BURST_SCHEDULE_MANAGEMENT}
          component={AdminPage}
      >
        {!user?.tenant_admin && <Redirect to="/" />}
      </PrivateRoute>
    </>;
  };
  return (
    <LookerProvider>
      <AnalyticsAPIProvider flags={(ccFlags?.[getFlag(FFlags.EnableConfigCat)] ? ccFlags : ldFlags)}>
        <Contexts>
          <div key={"app"} className="App">
            <DrawerProvider>
              <div key={v4()} className="da-sticky">
                <Navigation ref={useRef()} />
                {localStorage.getItem('auth_status') === 'true' && !user?.license_limit_exceeded && !location.pathname.startsWith('/app/p/admin') && <ActionBar />}
              </div>
              <div key={v4} className="parent">
                <ErrorBoundary
                  FallbackComponent={ErrorFallback}
                  onError={(error) => {
                    console.log(error);
                  }}
                >
                  <Switch>
                    <Route exact path={ROOT_ROUTE}>
                      <Redirect to={initRoute} />
                    </Route>
                    <Route exact path={FOLDERS_HOME_ROUTE}>
                      <Redirect to={FOLDERS_ALL_ROUTE} />
                    </Route>
                    <PrivateRoute
                      exact
                      path={FOLDERS_ROUTE}
                      component={FolderView}
                    />
                    <PrivateRoute
                      exact
                      path={DASHBOARDS_ROUTE}
                      component={ccFlags?.[getFlag(FFlags.ReusesLookerEmbedSession)] ? DashboardFrame : DashboardFrameV1}
                    />
                    <PrivateRoute
                      exact
                      path={LOOKS_ROUTE}
                      component={ccFlags?.[getFlag(FFlags.ReusesLookerEmbedSession)] ? LookFrame : LookFrameV1}
                    />
                    <PrivateRoute
                      exact
                      path={EXPLORE_ROUTE}
                      component={ccFlags?.[getFlag(FFlags.ReusesLookerEmbedSession)] ? ExploreFrame : ExploreFrameV1}
                    />
                    <Route
                      exact
                      path={PROVISIONING_ROUTE}
                      component={Provisioning}
                    />
                    <Route exact path={LOGOUT_ROUTE} component={Logout} />
                    <Route
                      exact
                      path={UNAUTHORIZED_ROUTE}
                      component={Unauthorized}
                    />
                    <Route exact path={ERROR_ROUTE} component={ErrorPage} />
                    <Route
                      exact
                      path={PROVISIONED_LICENSE_COUNT_EXCEEDED_ROUTE}
                      component={LicenseLimitExceededForProvisionedUser}
                    />
                    <PrivateRoute
                      exact
                      path={ADMIN_USER_MANAGEMENT}
                      component={AdminPage}
                    >
                      {!user?.tenant_admin && <Redirect to="/" />}
                    </PrivateRoute>
                    <PrivateRoute
                      exact
                      path={LICENSE_LIMIT_EXCEEDED_ROUTE}
                      component={LicenseLimitExceeded}
                    />
                    <InternalRoute
                      exact
                      path={ADMIN_INTERNAL_TOOLS_ROUTE}
                      component={InternalTools}
                    />
                    <InternalRoute
                      exact
                      path={ADMIN_INTERNAL_CUSTOMERS_ROUTE}
                      component={CustomerSearchPage}
                    />
                    {(ccFlags?.[getFlag(FFlags.EnableConfigCat)] ? ccFlags : ldFlags)?.[getFlag(FFlags.AdminViewSchedules)] && renderAdminSchedules()}
                    <Route path="*" component={NotFound} />
                  </Switch>
                  <AlertHandler />
                </ErrorBoundary>
              </div>
            </DrawerProvider>
          </div>
        </Contexts>
      </AnalyticsAPIProvider>
    </LookerProvider>
  );
};
